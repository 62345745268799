<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="[{ title: 'Bundles', to: useRoute().build(Bundle.routes().index) }, { title: 'Bundle Details' }]" />
        </template>
        <template #content>
            <v-card class="ma-3">
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.name" label="Name" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts">
import { Bundle } from '@app/purchase/bundle/models/Bundle';
import { useRoute } from '@shared/composables/useRoute';

const page = Bundle.routes().show.page;
</script>
