import type { IModel } from '@shared/types/Model';
import { useUtility } from '@shared/composables/useUtility';

const defaultExcludes: (keyof IModel)[] = ['object', 'id', 'operations', 'created_at', 'updated_at'] as const;
type DefaultExcludes = (typeof defaultExcludes)[number];

interface Options<T> {
    exclude?: (keyof T | DefaultExcludes | (string & NonNullable<unknown>))[];
    only?: (keyof T | DefaultExcludes | (string & NonNullable<unknown>))[];
    nullable?: (keyof T | DefaultExcludes | (string & NonNullable<unknown>))[];
}

const { isNull, isUndefined } = useUtility();

export function usePayload<T>(payload: T, options?: Options<T>): Partial<T> {
    const { exclude = [], only = [], nullable = [] } = options ?? {};
    exclude.push(...defaultExcludes);

    type SanitizedPayload = {
        [K in keyof T]: unknown;
    };
    const sanitizedPayload: Partial<SanitizedPayload> = {};
    for (const key in payload) {
        if (Object.prototype.hasOwnProperty.call(payload, key)) {
            const bodyParam = key;
            if (only.length && !only.includes(bodyParam)) {
                continue;
            }
            if (exclude.includes(bodyParam)) {
                continue;
            }
            if (isNull(payload[bodyParam]) && nullable.includes(bodyParam)) {
                sanitizedPayload[bodyParam] = null;
                continue;
            }
            if (!isNull(payload[bodyParam]) && !isUndefined(payload[bodyParam])) {
                sanitizedPayload[bodyParam] = payload[bodyParam];
            }
        }
    }

    return sanitizedPayload as Partial<T>;
}
