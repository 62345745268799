import { useForm } from '@shared/composables/useForm';
import { useRoute } from '@shared/composables/useRoute';
import { usePayload } from '@shared/composables/usePayload';
import { useInjectErrorState } from '@shared/composables/useProvideErrorState';
import { useWithValidationConfig } from '@shared/composables/useWithValidationConfig';
import { Certifier, type ICertifier } from '@app/land-hold/certifier/models/Certifier';
import type { FormOptions } from '@shared/types/Form';

export function useCertifierRepository<T extends ICertifier = ICertifier>() {
    const command = () => {
        const store = (data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                Certifier.routes().store,
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.transform((data) => usePayload<T>({
                    ...data,
                    public: data.public ? 1 : 0,
                })).submit(options);
            }

            return { form, execute };
        };

        const update = (id: string, data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                {
                    url: useRoute().build(Certifier.routes().update, {
                        id,
                    }),
                    method: Certifier.routes().update.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.transform((data) =>
                    usePayload<T>(
                        {
                            ...data,
                        },
                        {
                            exclude: ['logo', 'logo_id'],
                            nullable: ['logo_file'],
                        },
                    ),
                ).submit({
                    ...options,
                    forceFormData: true,
                });
            }

            return { form, execute };
        };

        const destroy = <U extends { id: string }>(id: string, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<U>({ id } as U).precognition(
                {
                    url: useRoute().build(Certifier.routes().destroy, {
                        id,
                    }),
                    method: Certifier.routes().destroy.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.submit(options);
            }

            return { form, execute };
        };

        return {
            store,
            update,
            destroy,
        };
    };

    const query = () => {
        const show = (id: string) => {
            return { endpoint: useRoute().build(Certifier.routes().show, { id }) };
        };

        return {
            show,
        };
    };

    return {
        command: command(),
        query: query(),
    };
}
