<template>
    <p>Are you sure you want to delete this Bundle?</p>
    <br />
    <p>[ {{ item.name }} ]</p>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import type { IBundle } from '@app/purchase/bundle/models/Bundle.ts';
import { useBundleRepository } from '@app/purchase/bundle/repositories/useBundleRepository.ts';

const props = defineProps({
    item: {
        type: Object as PropType<IBundle>,
        required: true,
    },
});

const destroy = useBundleRepository().command.destroy(props.item.id);
useInjectSubmitForm(destroy.execute);
</script>
