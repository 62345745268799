import { type ITransaction } from '@app/purchase/transaction/models/Transaction';
import type { MultiObjectInclude, SingleObjectInclude } from '@shared/types/ApiResponse';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import { Bundle, type IBundle } from '@app/purchase/bundle/models/Bundle';
import type { ISteward } from '@app/land-hold/steward/models/Steward';

export enum AvailableIncludes {
    Transactions = 'transactions',
    Steward = 'steward',
}

export interface TransactionsInclude {
    transactions: MultiObjectInclude<ITransaction>;
}
export interface StewardInclude {
    steward: SingleObjectInclude<ISteward>;
}
export type AllBundleIncludes = IBundle & TransactionsInclude & StewardInclude;
export type IndexBundleIncludes = IBundle & TransactionsInclude & StewardInclude;
export type ShowBundleIncludes = IndexBundleIncludes;

export function routes(): {
    index: IndexRoute<'bundles.index', IndexBundleIncludes>;
    show: ShowRoute<'bundles.show', ShowBundleIncludes>;
    destroy: DestroyRoute<'bundles.destroy'>;
    create: CreateRoute<'bundles.create'>;
    store: StoreRoute<'bundles.store'>;
    edit: EditRoute<'bundles.edit'>;
    update: UpdateRoute<'bundles.update'>;
} {
    return {
        index: {
            method: 'get',
            name: 'bundles.index',
            params: { include: [AvailableIncludes.Transactions, AvailableIncludes.Steward], orderBy: 'created_at', sortedBy: 'desc' },
            datatableHeaders: () => Bundle.headers(),
            page: usePage(),
        },
        show: { method: 'get', name: 'bundles.show', params: { include: [AvailableIncludes.Transactions, AvailableIncludes.Steward] }, page: usePage() },
        destroy: { method: 'delete', name: 'bundles.destroy', page: usePage() },
        create: { method: 'get', name: 'bundles.create', page: usePage() },
        store: { method: 'post', name: 'bundles.store', page: usePage() },
        edit: { method: 'get', name: 'bundles.edit', page: usePage() },
        update: { method: 'patch', name: 'bundles.update', page: usePage() },
    };
}
