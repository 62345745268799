import { useObjectUrl } from '@vueuse/core';

const TEMPLATES = [
    'small_square',
    'large_square',
    'avatar',
    'thumbnail',
    'small',
    'small320',
    'medium',
    'medium640',
    'medium800',
    'large',
    'large1600',
    'large2048',
    'original',
] as const;

const file = shallowRef()
const url = useObjectUrl(file)

export function useImage<T extends string | File | null>(image: T) {
    function buildUrl(template: typeof TEMPLATES[number] = 'original') {
        if (!image) {
            return '';
        }

        if (typeof image === 'string') {
            if (import.meta.env.VITE_APP_ENV === 'local') {
                return `${import.meta.env.VITE_IMAGE_DOMAIN}/v1/media/${template}/${image}`;
            }

            return `${import.meta.env.VITE_IMAGE_DOMAIN}/media/${template}/${image}`;
        }

        file.value = image;

        return url.value;
    }

    return {
        buildUrl,
    };
}
