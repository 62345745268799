import { Model } from '@shared/models/Model';
import type { ChipConfig, DataTableHeaders, DotNotationOf } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';
import { routes, type IndexBundleIncludes, type AllBundleIncludes } from '@app/purchase/bundle/routes/routes';
import type { ValuesOfConst } from '@shared/types/ApiResponse.ts';

export const BundleStatus = {
    Open: 'open',
    Closed: 'closed',
} as const;

export interface IBundle extends IModel {
    name: string;
    steward_id: string;
    status: ValuesOfConst<typeof BundleStatus>;
    date: string;
    transaction_count: number | null;
}

export class Bundle extends Model implements IBundle {
    name!: string;
    steward_id!: string;
    status!: ValuesOfConst<typeof BundleStatus>;
    date!: string;
    transaction_count!: number | null;

    public constructor(o: IBundle) {
        super(o);
        Object.assign(this, o);
    }

    public static override chipConfig(field: DotNotationOf<AllBundleIncludes>, item: AllBundleIncludes): ChipConfig {
        let color;
        switch (field) {
            case 'status':
                color = Bundle.getStatusColor(item.status);
                return { size: 'small', color, text: item.status };
            default:
                return { size: 'small' };
        }
    }

    public static override chips(): DotNotationOf<AllBundleIncludes>[] {
        return ['status'];
    }

    public static getStatusColor(status: ValuesOfConst<typeof BundleStatus>): string {
        switch (status) {
            case BundleStatus.Closed:
                return 'error';
            case BundleStatus.Open:
                return 'success';
            default:
                return 'info';
        }
    }

    public static statuses(): string[] {
        return Object.values(BundleStatus);
    }

    public static override headers(): DataTableHeaders<IndexBundleIncludes> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 113, minWidth: '113', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Name', key: 'name' },
            { title: 'Transactions', key: 'transaction_count' },
            { title: 'Steward', key: 'steward.data.name', sortable: false },
            { title: 'Status', key: 'status', sortable: false },
            { title: 'Date', key: 'date', date: true },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}
