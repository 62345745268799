<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.steward_id" v-model:form="form" :field-name="'steward_id'" :items="stewards" :loading="fetchingStewards" label="Steward" :disabled="hasTransactions" />
            </v-col>
            <v-col cols="12">
                <DatePicker v-model:field="form.date" v-model:form="form" label="Bundle Date" :field-name="'date'" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.status" v-model:form="form" :field-name="'status'" :items="Bundle.statuses()" label="Status" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { useRoute } from '@shared/composables/useRoute';
import { useFetchListData } from '@shared/composables/useFetchListData.ts';
import { type ISteward, Steward } from '@app/land-hold/steward/models/Steward.ts';
import { Bundle, type IBundle } from '@app/purchase/bundle/models/Bundle.ts';
import { useBundleRepository } from '@app/purchase/bundle/repositories/useBundleRepository.ts';

const props = defineProps({
    item: {
        type: Object as PropType<IBundle>,
        required: true,
    },
});

const update = useBundleRepository().command.update(props.item.id, props.item);
const form = update.form;
useInjectSubmitForm(update.execute);

const hasTransactions = !!props.item.transaction_count;

const { data: stewards, processing: fetchingStewards } = useFetchListData<ISteward>(useRoute().build(Steward.routes().index), 'appSection@steward::IndexStewardsPage');
</script>
