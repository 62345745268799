<template>
    <v-row no-gutters class="fill-height" align-content="space-between">
        <v-col cols="12">
            <DataTable
                title="Transactions"
                :headers="PublicTransaction.headers()"
                :model-class="PublicTransaction"
                :items="page.props.data"
                :search-endpoint="() => useRoute().build(PublicTransaction.routes().index)"
                :crud-config="{
                    showCreateAction: false,
                    showUpdateAction: false,
                    showDeleteAction: false,
                    showViewAction: true,
                    showCustomAction: false,
                }"
                :config="useConfig().publicTransaction"
                :show-breadcrumbs="false"
            >
                <template #intro>
                    <v-row justify="center" align="center" no-gutters>
                        <v-col cols="auto" align-self="center" class="mt-3">
                            <h1 style="font-size: large">Carbono Biodiverso</h1>
                        </v-col>
                    </v-row>

                    <v-row justify="center" align="center" no-gutters>
                        <v-col cols="auto" align-self="center">
                            <h2 style="font-size: large; font-weight: normal">Public Carbon Registry</h2>
                        </v-col>
                    </v-row>

                    <v-row justify="center" align="center" no-gutters>
                        <v-col cols="12" md="10" lg="7" xl="6" align-self="center" class="mt-3">
                            <p style="font-size: medium; text-align-last: center">The Carbono Biodiverso Registry is a public platform dedicated to tracking and showcasing carbon offset projects utilizing the Local Protocol for Subnational Actions for the Regeneration of Forests. This registry ensures transparency, accountability, and credibility in the fight against climate change, providing a detailed record of carbon stock assessments and transactions.</p>
                        </v-col>
                    </v-row>

                    <v-row justify="center" align="center" no-gutters>
                        <v-col cols="auto" align-self="center" class="mt-3 mb-4">
                            <a style="font-size: medium;" href="https://sierragorda.net/en/home/" target="_blank">How it works</a>
                        </v-col>
                    </v-row>
                </template>
            </DataTable>
        </v-col>
        <v-col cols="12">
            <ThePublicRegistryFooter />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { PublicTransaction } from '@app/purchase/transaction/models/PublicTransaction';
import { useConfig } from '@shared/composables/useConfig';
import { useRoute } from '@shared/composables/useRoute';

const page = PublicTransaction.routes().index.page;
</script>
