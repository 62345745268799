<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="[{ title: 'Certificate Owners', to: useRoute().build(Certifier.routes().index) }, { title: 'Certificate Owner Details' }]" />
        </template>
        <template #content>
            <v-card class="ma-3">
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="3" xl="2">
                                <v-chip :color="page.props.data.public ? 'success' : 'error'" >
                                    {{ Certifier.getPublicFieldLabel(page.props.data.public) }}
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" xl="2">
                                <ReadOnlyTextField :value="page.props.data.name" label="Name" />
                            </v-col>
                            <v-col cols="12" sm="3" xl="2">
                                <ReadOnlyTextField :value="page.props.data.embed_url" label="Embedded Map" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" xl="2">
                                <v-card>
                                    <v-img :src="useImage(page.props.data.logo).buildUrl() ?? ''" alt="logo" cover/>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <GenericActionDialog confirm-btn-text="Update" title="Edit Certificate Owner" :disabled="false" @confirm="confirm($event)">
                        <template #activator="{ props: activatorProps }">
                            <v-btn v-bind="activatorProps" :loading="processing">Edit</v-btn>
                        </template>
                        <template #body>
                            <UpdateCertifierDialog :item="page.props.data" />
                        </template>
                    </GenericActionDialog>
                </v-card-actions>
            </v-card>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts">
import { type ICertifier, Certifier } from '../../models/Certifier';
import { useSubmitForm } from '@shared/composables/data-table/useSubmitForm';
import { useRoute } from '@shared/composables/useRoute';
import type { SingleObjectResponse } from '@shared/types/ApiResponse';
import { useImage } from '@shared/composables/useImage.ts';

const page = usePage<SingleObjectResponse<ICertifier>>();

const { confirm, processing } = useSubmitForm();
</script>
