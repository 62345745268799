<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
            <v-col cols="12" sm="6">
                <v-row justify="space-around">
                    <v-col cols="auto">
                        <v-switch v-model="form.public" inset :color="Certifier.getPublicFieldStateClassColor(form.public)" :label="Certifier.getPublicFieldLabel(form.public)" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="12">
                <ValidatableTextField v-model:form="form" placeholder="https://example.com" label="Embedded Map" :field="'embed_url'" />
            </v-col>
            <v-col cols="12" sm="12">
                <v-file-input v-model="form.logo_file" :error-messages="transformError(form.errors['logo_file'])" append-inner-icon="mdi-camera" prepend-icon="" variant="outlined" label="Logo" clearable accept="image/png, image/jpeg" @click:clear="resetFormLog" />
            </v-col>
            <v-col v-if="hasLogo" cols="12">
                <v-card>
                    <v-img :src="useImage(form.logo_file ?? form.logo).buildUrl() ?? ''" alt="logo" cover />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { Certifier, type ICertifier } from '@app/land-hold/certifier/models/Certifier';
import { useCertifierRepository } from '@app/land-hold/certifier/repositories/useCertifierRepository';
import { useImage } from '@shared/composables/useImage';
import { useUtility } from '@shared/composables/useUtility';

const { transformError } = useUtility();

const props = defineProps({
    item: {
        type: Object as PropType<ICertifier>,
        required: true,
    },
});

const update = useCertifierRepository<
    ICertifier & {
        logo_file: File | null | undefined;
    }
>().command.update(props.item.id, {
    ...props.item,
    logo_file: undefined,
});
const form = update.form;

useInjectSubmitForm(update.execute);

const hasLogo = computed(() => form.logo_file ?? form.logo);

function resetFormLog() {
    form.logo_file = null;
    form.logo = null;
}
</script>
